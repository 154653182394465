.Value-propositions-Section1{
    background: #aee0ff;
    padding: 40px 0px;
    margin-top: 3rem;
}
.Value-propositions-Section1-content h1{
    color: #0a4999;
    font-weight: 800;
    letter-spacing: 0px;
    font-size: 36px;
    text-align: left;
    font-family: "Roboto", sans-serif;
}
.Value-propositions-Section1-content p{
    font-size: 20px;
    color: #555;
    margin: 0;
    text-align: left;
}
.Value-propositions-Section2-content{

    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 1rem;
}
.Value-propositions-Section2-content h2{
    color: #0a4999;
    font-size: 38px;
    font-weight: 800;
}