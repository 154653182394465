
.footer-main{
    /* background:linear-gradient( to bottom, rgba(245, 246, 252, 0.52)), url(../Assets/Footer/image.png); */
    background: url(../Assets/Footer/footer-bg.png);
    /* background: linear-gradient(180deg,#0A102F 0%,#1b203b 100%), url("../Assets/Footer/image.png"); */
    background-size: cover;
    padding: 20px;
    color: #fff;
    overflow-x: hidden;
}
.footer-logo{
    width: 30%;
}

.head-content h2{
    font-size: 38px;
    font-weight: 700;
    padding-bottom: 20px;
}
.footer-mail{
    display: flex;
}
.email-icon{
    border-radius: 8px;
    margin-right: 15px;
}
.footer-scoial-icon p{
   margin-right: 15px;
}
.footer-scoial-icon i{
    color: #fff;

}
.footer-input{
    background: transparent;
    border: 1px solid gray;
    height: 50px;
    width: 500px;
    position: absolute;
}
.footer-btun{
    margin-left: 365px;
    margin-top: 5px;
    
}
.our-services-footer h5{
font-size: 20px;
font-weight: 700;
margin: 1.5rem 0;
}
.our-services-footer p{
    font-size: 14px;
    font-weight: 300;
    }
.subscribe-now{
    width: 32% !important;
    background: #0566f8 !important ;
    color: #fff !important;
    border: none !important;
    
}

.icon {
    width: 28px;
    margin-right: 0.7rem;
  }
  
  .social-media-icons {
    /* padding: 0.5rem 0 0 0; */
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .footer-social-icons{
    display: flex;
    gap: 1rem;
  }
  .footer-social-icons img{
    width: 30px;
    height: 30px;
    background: none;
  }
  .social-media-icons p {
    color: #fff !important;
    text-align: start;
  }
  
  .social-icons-footer {
    display: flex;
    /* margin-top: 0.5rem; */
  }
  
  .social-icons-footer a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    /* background: linear-gradient(45deg, #1abc9c, #149279); */
    background: #fff !important;
    color: rgb(71, 150, 240) !important;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons-footer a:hover {
    transform: scale(1.05);
  }
@media screen and (min-width:344px) and (max-width:360px){
    .footer-input{
        width: 287px;
        height: 60px;
        overflow-x: hidden;
        border: 2px solid #fff;
    }
    .footer-btun{
       margin-left: 200px;
        margin-top: 5px;
        font-size: 11px;
    }
}
@media screen and (min-width:360px) and (max-width:375px){
    .footer-input{
        width: 302px;
        height: 60px;
        overflow-x: hidden;
        border: 2px solid #fff;
    }
    .footer-btun{
       margin-left: 200px;
        margin-top: 5px;
        font-size: 11px;
    }
}
@media screen and (min-width: 375px) and (max-width: 430px)  {

    .footer-input{
        width: 311px;
        height: 55px;
        overflow-x: hidden;
        border: 2px solid #fff;
    }
    .footer-btun{
       margin-left: 200px;
        margin-top: 10px;
        font-size: 12px;
    }
}