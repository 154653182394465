.optimize-content{
    display: flex;
    flex-direction: column;
    
    margin-left: 1rem;
    
}
.optimize-content h2{
    font-size: 35px;
    font-weight: 900;
    line-height: 50px;
    font-family: "Mukta", sans-serif;
    text-align: left;
    
}
.optimize-content p{
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    font-family: "Mukta", sans-serif;
    text-align: left;
    margin-top: 1.2rem;
}
.optimize-content span {
    text-align: left;
    font-size: 15px;
}
.optimize-image{
    border-radius: 0px 0px 0px 50px;
    width: 100%;
    height: 100%;
    margin-top: rem;
}
.optimze-col{
    background: #faf8f8;
    padding: 2rem;
    border-radius: 0px 0px 50px 0px;
}
.optimze-col3{
    background: #faf8f8;
    padding: 2rem;
}
.our-achivements{
    /* background-image: url(../Assets/Home/achivements-bg-image.jpg) ; */
    background: #a2c8f1;
    background-repeat: no-repeat;
    background-size: auto;
    background-size: 100% 100%;
    padding: 2rem;
   

   
}
.achivements-content h2{
    font-size: 40px;
    font-weight: 900;
    line-height: 50px;
    font-family: "Mukta", sans-serif;
    text-align: left;
    color: #1F2A59;
}
.achivements-content{
    display: flex;
    flex-direction: column;
    
}
.ach-img{
    width: 60%;
}
.ach-img-content{
    font-size: 12px;
}
.ach-awards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
}
.achivements-content span{
    font-size: 12px;
    text-align: left;
    
}
.achi-image-content p{
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.client-reviews{
    background: #fff;
    padding: 3rem 0;
}
.client-review-content{
    display: flex;
    flex-direction: column;
    text-align:left ;
}
.client-review-content h4{
    color: #454647;
    font-weight: 600;
}
.client-review-content h2{
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    font-family: "Mukta", sans-serif;
    margin: 1rem 0;
}
.client-review-content p{
    font-size: 14px;
    text-align: left;
    margin-top: 10px;
}
.rating-starts{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    background: #0588f3;
    color: #fff;
    height: 50%;
}
.rating{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    background: #1F2A59;
    color: #fff;
    height: 50%;

}
.client-name{
    display: flex;
    flex-direction: column;
    text-align: left;
/* margin-top: 2rem; */

}
.client-name h6{
    font-size: 18px;
    font-weight: 700;
}
.client-name p{
    font-size: 16px;
    font-weight: 700;
    color: #454647;
}
.client-review-text p{
text-align: left;
}
.client-review-image{
    width: 90%;
}
.our-products-heading{
    font-size: 38px;
    font-weight: 800;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.our-products-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 0rem 0;
    height: 650px;
}
.our-products-content h3{
    font-size: 25px;
    font-weight: 800;
    text-align: left !important;

}
.service-button{
    margin-top: 0.7rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.service3-button{
    margin-top: 3.7rem;
}
.service2-button{
    margin-top: 0.3rem;
}

.our-products-content img{
    width: 100%;
    height: 250px;
}
.our-products-content p{
    font-size: 14px;
    margin-top: 1rem;
}
.details-btn{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    margin: 1rem;
}
.details-content{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: #454647;
}
.details-acordian1{
    background: #069E43 !important;
    color: #fff;
}
.details-acordian2{
    background: #1F2A59 !important;
    color: #fff;
}
.recognizations{
    background: url(../Assets/Home/Group1171277311.png);
    background-size: cover;
    padding: 3rem 0;
    background-color: #fff;
}
.recognization-image{
    background: url(../Assets/Home/Ellipse112.png);
    background-size: 100% 100%;
    padding: 1rem;
    /* margin-top: 10rem; */


}
.recognize-tab-buttons img{
    width: 65%;
}

.recognize-tab{
    display: flex;
    justify-content: center !important;
    border: none;
  


}

.nav-link.active{
    background: #004B78 !important;
    color: #fff !important;
    border-radius: 5px !important;
}
.recognizations-heading h2{
    font-size: 25px;
    font-weight: 900;
    text-align: left;
    margin-bottom: 3rem;
}

.accordion {
    max-width: 750px;
    margin: 0 auto;
  }
  
  .accordion-item {
    margin: 1rem 0;
    border-bottom: 1px solid #ccc;
    overflow: hidden; /* Add overflow property to prevent content from affecting layout */
  }
  
  .accordion-title {
    background-color: #004B78; /* Updated question box color */
    padding: 15px;
    cursor: pointer;
    border-top: 1px solid #ccc;
    color: #fff; /* Question text color */
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .accordion-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }
  
  .accordion-content {
    padding: 15px;
    background-color: #fff;
    color: #333;
  }

  .accordian-btn{
    display: flex;
    justify-content: flex-end;
  }
  
  .accordion-item.active .accordion-title {
    background-color:  #069E43;
  }
  
  .accordion-item.active .accordion-icon {
    transform: translateY(-50%) rotate(180deg);
  }

  #widget-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

  .carousel-container {
    position: relative;
    width: 100%;
    /* margin: auto; */
  }
  
  .carousel-slide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 200px; /* Adjust as needed */
   text-align: left;
    /* Light border for better visibility */
    /* border-radius: 5px; */
    padding: 20px;
    margin-top: 3rem;
    gap: 1rem;
    /* box-sizing: border-box; */
  }
  .carousel-slide h5{
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin: 1rem 0;
  }
  .carousel-slide p {
    font-size: 15px; /* Adjust as needed */
    text-align: left;
    color: #fff;
    margin: 1rem 0;

  }
  .empty-div{
    width: 60px;
    height: 3px;
    background: #ECA30C;
    border-radius: 5px;
    margin: 1rem 0;

  }
  
  .carousel-buttons {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    margin-right: 2rem;
  }
  
 
  .pre-btn{
    border: 2px solid #fff;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    background: none;

  }
  .next-btn{
    border: 2px solid #fff;
    color: #000;
    padding: 10px;
    cursor: pointer;
    background: #fff;
  }
  
  .carousel-buttons button:hover {
    background-color: #0056b3;
  }
  .product-carousel{
    /* background:url(../Assets/Home/product-carousel-bg.jpg), linear-gradient(#0B4A99); */
    /* background: url(../Assets/Home/product-carousel-bg.jpg), linear-gradient(#0B4A99); */
    background: #004B78;
    background-image:url(../Assets/Home/product-carousel-bg.png) ;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position:initial; */
    margin: 0.5rem 0;
    padding: 3rem;
    
  }

  /* Aboutus css */
.about-us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
  .about-us-main{
    overflow-x: hidden;
}
.about-us h5{
    font-weight: 800;
}
.about-us h2{
    font-weight: 800;
    padding-bottom: 20px;
}
.about-us button{
    background: rgb(6, 6, 92);
    border: none;
    margin-bottom: 30px;
}
.group-icon{
    font-size: 40px !important;
    margin-right: 10px;
}
.loyal-business{
    border-right: 1px solid gray;

}
.about-bottom{
    display: flex;
    width: 80% !important;
    /* padding: 0px 5px 5px 10px ; */
}
.about-bottom h6{
    font-weight: 800;
}
.bottom-content{
    font-size: 12px !important;
}
.about-border-bottom{
    content: '';
    width: 90%;
    border: 6px solid rgb(7, 7, 90);
    margin-left: 40px;
    
}
.about-us2-image{
    width: 100%;
    margin-left: -40px;
    margin-top: -40px;
    margin-bottom: 40px;
}
.about-pilog{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
}

.gartner-reviews{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.nav-link{
    color: #004B78 !important;
}
  @media only screen and (max-width: 768px) {
    .carousel-buttons {
        position: relative;
        right: 10px;
        top: 50%;
        transform: translateY(40%);
        display: flex;
        /* flex-direction: column; */
        gap: 10px;
        margin-right: 0rem;
    }
    .optimize-content{
        display: flex;
        flex-direction: column;
        
        margin-left: 0rem;
        
    }
    .optimize-content h2{
        font-size: 25px;
        font-weight: 900;
        line-height: 30px;
        font-family: "Mukta", sans-serif;
        text-align: left;
    }
  }


.new-home-sec{
    background: url('../Assets/Home/Data-science-driven-data-quality-management-HomeBanner.jpg');
    background-size: 100% 100%;
    padding: 5rem 0;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Hero-heading{
    font-size: 4rem;
    font-weight: 700;
    color: #1F2A59;
    /* background: -webkit-linear-gradient(#023a5e, #0588f3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.hero-button{
    /* background: linear-gradient(#0b4a99, #AEE1FF); */
    background: #0b4a99;
    padding: 0.8rem 2rem;
    margin-top: 2rem;
    border-radius: 20px;
    color: #fff;
    display: flex;
    justify-content: flex-start;
}
  