.header-tags {
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  padding: 1rem;
}

.header-tags2 {
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  padding: 0.5rem;
  color: #000 !important;

}

.header-tags-top {
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;

  color: #000 !important;
}

#navbarScrollingDropdown {
  color: #000 !important;
}

Link {
  color: #000 !important;
}

a {
  color: #000 !important;
  text-decoration: none !important;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  /* margin-left: 1rem; */

}

.search__input {
  font-family: inherit;
  font-size: inherit;
  background-color: #fff;
  border: none;
  color: #3a3939;
  padding: 0.4rem 1rem;
  border-radius: 30px;
  width: 14em;
  transition: all ease-in-out .5s;
  margin-right: -2rem;
  border: 1px solid #3a3939;
}

.search__input:hover,
.search__input:focus {
  box-shadow: 0 0 1em #00000013;
}

.search__input:focus {
  outline: none;
  background-color: #fff;
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #5a5959;
}



.search__button {

  background: none;
  margin-top: .1em;
}

.search__button:hover {
  cursor: pointer;
}

.two-navs {
  display: flex;
  flex-direction: column;
}

.navbar .dropdown-toggle::after {
  content: '';
  margin-left: 0px;
  display: none;
}

.dropsmart {
  text-decoration: none !important;
  color: black;
}

.pilog-nav {
  height: 100%;
}

/* / Header.css / */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  border: 1px solid #555 !important;
  margin-top: 1rem !important;
  /* margin-top: 0; / Ensure the dropdown aligns properly / */
}

/* / Ensure the dropdown shows on hover / */

.navbar .navbar-nav .nav-link {
  padding: 0px 10px;
  color: #000 !important;
  font-weight: 500;
  outline: none;
}

.navbar-expand-lg .navbar-collapse {
  /* gap:  5rem; */
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.nav-bar-top {
  background: #fff;
  border: 1px solid !important;
}

.top-links {
  margin-top: 0rem;
  font-size: 15px;
  font-weight: 600 !important;
  color: #000 !important;
}

.model-btn {
  margin-top: 10px !important;
}

.nav-fix {
  position: fixed;
  transition: all 0.3s ease-in-out;
  animation: nav-slider .22s forwards;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 1080;
}

@keyframes nav-slider {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 986px) {
  .header-tags {
    padding: 0.2rem 0;
    justify-content: center;
    align-items: center;
    text-align: center;

  }


  .navbar-nav-scroll {
    height: 250px !important;
  }

}



@media only screen and (max-width: 1285px) {
  .header-tags {
    padding: 0.3rem 0.5rem;
    margin-top: 0.5rem;
  }

  .header-tags2 {
    padding: 0.5rem;
  }

}