.Strategic-Advisory-Section1{
    /* background: url(../Assets/Strategic-Advisory/AI-Advisory-BG2.png); */
    background: #aee0ff;
    
    /* background-size: cover; */
    background-repeat: no-repeat;
    padding: 40px 0px;
    margin-top: 3rem;
}
.Strategic-Advisory-Section1-content h1{
    color: #0a4999;
    font-weight: 800;
    letter-spacing: 0px;
    font-size: 36px;
    text-align: left;
    font-family: "Roboto", sans-serif;
}
.Strategic-Advisory-Section1-content p{
    font-size: 20px;
    color: #555;
    margin: 0;
    text-align: left;
}

.Strategic-Advisory-Section2-content{
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    
    gap: 1rem;
}
.Strategic-Advisory-Section2-content h2{
    color: #0a4999;
    font-size: 38px;
    font-weight: 800;
}


.iMirAi-points{
    padding: 2rem;
    border-radius: 8px;
    border: 7px solid blue;
    margin: 1rem 0;
    text-align: left;
    height: 220px;
}