
.usecase-redefine-content{
padding: 0rem 3rem;
}
.usecase-redefine-content h5{
color: rgb(4, 115, 189);
font-weight: 600;
text-align: start;
}
.usecase-redefine-content p{
  color: rgb(80, 154, 204);
  text-align: start;
  }