.contact-us-section1{
    background: url(../Assets/Contact/Contact\ us-Banner.png);
    background-size: 100% 100%;
    padding: 10rem 0;
    margin-top: 3rem;

}
.contact-us-heading h1{
    font-size: 3rem;
    font-weight: 800;
    color: #fff;
    text-align: start;

}
.contact-us-heading p{
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    text-align: start;
}
.contact-us-address{
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 3rem;
    background: #035292;
    color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: start;
    gap: 1rem;

}
.icons{
   font-size: 3rem !important;
}

.contact-social-icons{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    margin: 3rem;
    padding: 1rem;
    text-align: start;
    
}
.contact-social-icons h3{
    text-align: start;

}

.contactus-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1.5rem;
    text-align: start;
    margin-top: -4rem;
  background: #fff;

}
.contactus-form-inputs-name{
    display: flex;
    flex-direction: column;
}

.contact-inputs{
    padding: 0.8rem ;
    text-align: start;
    background: #e1e7ec;
    border-radius: 25px;
    /* width: 400px; */


}
.contactus-social-icons{
    display: flex !important;
    gap: 1rem;

}
.contactus-social-icons img{
    width: 40px;
    height: 40px;
    
 }