/* .hero-carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.hero-carousel__background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: flex-end;
}

.hero-carousel__thumbnails {
  width: 18%;
  height: 100vh;
  background-color: rgba(216, 209, 209, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.thumbnail {
  width: 100%;

  height: 150px;
  margin-bottom: 5px;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.3s;
}

.thumbnail.selected {
  opacity: 0.5;
}


@media (max-width: 768px) {
  .hero-carousel {
    height: 75vh;
  }

  .hero-carousel__thumbnails {
    width: 35%;
  }
}

@media (max-width: 480px) {
  .hero-carousel {
    height: 50vh;
  }

  .hero-carousel__thumbnails {
    width: 50%;
    flex-direction: row;
    overflow-x: scroll;
    padding: 5px;
  }

  .thumbnail {
    width: auto;
    height: 80px; 
    margin-bottom: 0;
    margin-right: 5px;
  }
} */


.hero-carousel {
  position: relative;
  width: 100%;
  height: 75vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-carousel__content {
  display: flex;
  width: 100%;
  height: 100%;
}

.hero-carousel__background {
  flex: 1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-carousel__thumbnails {
  width: 18%;
  
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.thumbnail {
  width: 100%;
  height: 160px;
  margin-bottom: 5px;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.3s;
}

.thumbnail.selected {
  opacity: 0.5;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-carousel {
    height: 75vh;

  }
  .hero-carousel__content {
    flex-direction: column;
    height: 95vh;
  }
  .hero-carousel__thumbnails {
    width: 100%;
    height: 25vh;
    flex-direction: row;
    overflow-x: scroll;
    padding: 5px;
  }

 
}

@media (max-width: 480px) {
  .hero-carousel {
    height: 66vh;
  }

  .hero-carousel__content {
    flex-direction: column;
    height: 65vh;
  }

  .hero-carousel__thumbnails {
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
    padding: 5px;
  }

  .thumbnail {
    width: auto;
    height: 80px;
    margin-bottom: 0;
    margin-right: 5px;
  }
}

@media (min-width: 1460px) {
  .hero-carousel {
    position: relative;
    width: 100%;
    height: 66vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}
@media (min-width: 1920px) and (max-width: 2570px)  {
  .hero-carousel {
    position: relative;
    width: 100%;
    height: 55vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
}
@media (max-width: 1290px) {
  .hero-carousel {
    position: relative;
    width: 100%;
    height: 60vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}
@media (min-width: 350px) {
  .hero-carousel {
    position: relative;
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}