.padding-top-bottom {
    padding: 4rem 0;
    margin: 1rem 0;
}

.headers-border-border-line {
    color: #0b4a99;
    border-top: 7px solid;
    border-image: linear-gradient(to right, #AEE1FF 50%, transparent 50%) 100% 1;
    padding-top: 0.57rem;
}

.headers-border-border-line-ai-transform {
    color: #0b4a99;
    border-top: 7px solid;
    border-image: linear-gradient(to right, #AEE1FF 25%, transparent 25%) 100% 1;
    padding-top: 0.57rem;
}

.header-color {
    color: #0b4a99
}

.ai-adoption-grid h2 {
    color: #0b4a99;
    font-weight: 700;
    margin-left: 10px;
    text-align: center;
    font-size: 2.5rem;
}

.data-ai-adoption {
    border-bottom: 1px solid #000
}

.critical-role-text-section {
    text-align: left;
}

.critical-role-text-section p {
    margin: 0.9rem 0;
}

.critical-role-text-section b {
    color: #0b4a99;
}

.did-you-know-section p:not(:first-child) {
    margin: 1.2rem 0;
}

.did-you-know-section b {
    font-weight: 900;
    color: #0b4a99;
}

.bg-hero {
    background-image: url("../Assets/data-excellence/Data-Quality-Fuels-AI-Excellence-BG3.jpg");
    background-size: cover;
}

.accordian-section h6 {
    font-weight: 600;
}

.bg-color-section {
    background-color: #f7faff;
}

.contact-us-btn button {
    background: #0b4a99;
    color: #fff;
    width: auto;
}

.contact-us-btn {
    margin-top: 10px;
}

.contact-us-btn button:hover {
    color: #0b4a99;

}

.critical-line-break{
    word-spacing: 5px;
}