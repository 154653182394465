

.ldg-section{
    background: url(../Assets/LDG/Ldg-section1-bg-image.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 8rem 0;
    justify-content: center;
    align-items: center;
}
.ldg-section-1{
    display: flex;
    justify-content: flex-start;
    align-items:end;
    text-align: start;
    /* padding-left: 2rem; */
    /* margin-right: 2rem; */
    /* margin-top: 4rem; */
}
.ldg-sec-1-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1.2rem;
    /* margin-top: 5rem; */
}
.ldg-sec-1-content h1{
  background-image: linear-gradient(to right, #0b4a99, #cb1618);
  background-clip: text;
  color: transparent;
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 42px;
  font-family: "Roboto", sans-serif;
 
}
.ldg-sec-1-content p{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.section2-ldg{
    background: url(../Assets/LDG/Ldg-section2-bg-image-1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 3rem 0;
    justify-content: center;
    align-items: center;
}
.ldg-section-2{
    /* margin-top: 15rem; */
    justify-content: center;
    align-items: center;
    text-align: start;
   padding: 2rem;
}
.ldg-section-2 h2{
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    margin: 3rem 0;
    font-family: "Roboto", sans-serif;
}
.ldg-sec2-card-content{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    box-shadow: black;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 1rem;
    gap: 2rem;
}
.ldg-sec2-card-content h5{
    color: #0b4a99;
    font-size: 28px;
    font-weight: 600;

}
.ldg-sec2-card-content2{
    text-align: start;
    margin: 1rem;
    padding: 0.5rem 0
}
.ldg-sec2-card-content-points{
    display: flex;
    gap: 1rem;
    margin: 0.7rem 0;
    align-items: center;
}
.ldg-sec2-card-content-points p{
    font-size: 16px;
}
.ldg-updated-sec-2-heading h2{
   color: #0b4a99;
}



/* section-two */
.ldg-section-two{
    background: url(../Assets/LDG/LDD_BG-3.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
   padding: 3rem;
    /* justify-content: center;
    align-items: center; */
}

.key-aspect-images-parts{
    /* background: #a9cbe9; */
    width: 20%;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* padding: 0.5rem; */
    /* border-radius: 10px 10px  0 10px; */
    position: relative;
    right: 1px;
}
.key-aspect-images{
    position: relative;
}
.image-1{
    width: 100%;
    height: auto;
}
.image-gif{
   
    position: absolute;
    bottom: 64px;
    left: 15px;
    
        width: 27%;
    
}
.key-aspect-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
}
.empty-div{
    width: 140px;
    height: 5px;
    border-radius: 0px;
    background: #aee0ff;
}
.key-aspect-section-heading h2{
    color: #0b4a99;
    /* font-size: 40px; */
    font-weight: 600;
    letter-spacing: -1.5px;
    font-family: "Roboto", sans-serif;
    

}


/* section-3 */
.ldg-section-3{
    background: url(../Assets/LDG/LDD_BG-5.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
   padding: 4rem;
    justify-content: center;
    align-items: center;
}
.impactful-heading h2{
    color: #0b4a99;
    /* font-size: 40px; */
    font-weight: 600;
    letter-spacing: -0.5px;
    font-family: "Roboto", sans-serif;
    
}
.impact-business-topics{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
}
.impact-image{
    border-radius: 10px 10px 0 10px;
    padding: 1rem;
    margin: 1.5rem;
    width: 40%;
    height: 50%;
}
.impact-business-content h6{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.5px;
    font-family: "Roboto", sans-serif;
    margin: 1rem 0;
    
}
.impact-business-content p{
font-size: 15px;
}

.ldg-last-section{
    background: #fff;
    padding: 2rem 0;
}
.ldg-idxp-heading h2{
    font-size: 28px;
    font-weight: 700;
 
    font-family: "Roboto", sans-serif;
    margin: 1.5rem 0;
}

.contemporary-trend-LDG{
    background: url(../Assets/LDG/contemporary-trend-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
   padding: 0rem;
    justify-content: center;
    align-items: center;
}
.contemporary-trend-LDG-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
}
.contemporary-trend-LDG-content h3{
    margin: 2rem 0;
    color: #0b4a99;
}

@media only screen and (max-width: 600px) {
    .impactful-heading{
        width: 100%;

    }
    .impactful-heading h2{
font-size: 22px;
    }
    .impact-image img{
        width: 100px !important;
    }
    .impact-image{
        border-radius: 10px 10px 0 10px;
        padding: 1rem;
        margin: 1.5rem;
        width: 100%;
        height: 50%;
    }
}



.ldg-updated{
    background-color: #AEE1FF;
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem
}
.ldg-updated-heading h1{
    font-size: 2.5rem;
    font-weight: 700;
    color: #0b4a99;
}
.ldg-updated-banner-side{
    width: 70%;
}
.ldg-updated-sec-2{
   
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ldg-updated-sec-3{
   background: #f1f1f1;
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ldg-updated-sec-4{
   
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ldg-updated-sec-5{
    background: #f1f1f1;
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ldg-updated-sec-5-heading h2{
color: #0b4a99;
}
.ldg-updated-sec-6{
    background: url("../Assets/LDG/banner-side-image.jpg");
    background-size: cover;
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (min-width: 1600px) {
    .ldg-updated-banner-side{
        width: 60%;
    } 
}