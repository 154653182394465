.cycle-tab-container {
    margin: 30px auto;
    width: 100%;
    padding: 30px;
    box-shadow: 0 0 10px 2px #ddd;
    background: #fff;
  }
  
  .cycle-tab-container a {
    color: #173649;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cycle-tab-container .tab-icon {
    margin-right: 8px;
   
    display: flex;
    flex-direction: column;
  }
  .tab-icon img{
    width: 30px;
    height: 30px;
  }
  
  .tab-pane {
    text-align: center;
    margin: 30px auto;
    width: 100%;
    max-width: 100%;
  }
  
  .tab-pane img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  
  .fade {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade.active {
    opacity: 1;
  }
  
  .cycle-tab-item {
    width: 180px;
  }
  
  .cycle-tab-item:after {
    display: block;
    content: '';
    border-bottom: solid 3px orange;
    transform: scaleX(0);
    transition: transform 0ms ease-out;
  }
  
  .cycle-tab-item.active:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
    transition: transform 5000ms ease-in;
  }
  
  .nav-link:focus,
  .nav-link:hover,
  .cycle-tab-item.active a {
    border-color: transparent !important;
    color: orange;
  }

  .slick-arrow::before{
    color: orange !important;
  }
  