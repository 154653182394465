.iDQM-Section1 {
    background: url(../Assets/iDQM/iDQM-BG4\ 1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 15rem 0 20rem 0;
}

.iDQM-Section1 h1 {
    background-image: linear-gradient(to right, #035292, #cb1618);
    background-clip: text;
    color: transparent;
    font-weight: 800;
    letter-spacing: 0px;
    font-size: 42px;
    font-family: "Roboto", sans-serif;
}

.iDQM-Section1 p {
    font-size: 20px;
    color: #555;
    margin: 0;
}

.iDQM-Section2 {
    padding: 5rem 0;
}

.iDQM-Section2-items {
    border: 1px solid #555;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: #555;
    margin: 0 1rem;

}

.iDQM-Section2-items:hover {
    background: #035292;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: #555;
    margin: 0 1rem;

}

.iDQM-Section3 {
    background: url(../Assets/iDQM/BG-section3.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 2rem 0;
}

.iDQM-Section3-heading {
    padding: 2rem 3rem;
    color: #0b4a99;
}

.iDQM-Section3-heading h2 {
    font-size: 35px;
    font-weight: 900;

}

.iDQM-Section3-heading p {
    color: #555;
    margin-top: 1rem;

}

.iDQM-Section3-items ul li {
    font-size: 14px;
}

.iDQM-Section4 {
    background: #f5fcfb;
    padding: 3rem 0;
}

.iDQM-Section4-heading {
    padding: 0rem 0;
}

.iDQM-Section4-heading h2 {
    justify-content: center;
    align-items: center;
    text-align: start;
    font-size: 35px;
    font-weight: 900;

}

.iDQM-Section4-items ul li {
    font-size: 14px;
}

.carousel-control-prev {
    left: -60px !important;
}

.carousel-control-next {
    right: -60px !important;
}

.carousel-indicators {
    bottom: -60px !important;
}

.iDQM-Section5 {
    background: #F1F7FF;
    padding: 3rem 0;
}

.iDQM-Section5-heading h2 {
    justify-content: center;
    align-items: center;
    text-align: start;
    font-size: 35px;
    font-weight: 900;

}

.iDQM-Section5-items,
.iDQM-Section6-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.iDQM-Section5-items p {
    font-size: 14px;
    text-align: start;
}

.iDQM-Section6 {
    background: #F1F7FF;
    padding: 3rem 0;
}

.iDQM-Section6-heading h2 {
    justify-content: center;
    align-items: center;
    text-align: start;
    font-size: 35px;
    font-weight: 900;

}

.iDQM-Section6-items p {
    font-size: 14px;
    text-align: left;

}

.iDQM-Section6-items h6 {
    font-size: 16px;
    text-align: left;

}

.iDQM-Section6-items ul li {
    font-size: 14px;
    text-align: left;

}

.iDQM-Section7 {
    /* background: url(../Assets/iDQM/BG-section7.jpg); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 8rem 0;
}

.iDQM-Section7-heading h2 {
    justify-content: center;
    align-items: center;
    text-align: start;
    font-size: 35px;
    font-weight: 900;
}

.iDQM-Section7-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.iDQM-Section7-items p {
    font-size: 14px;
    text-align: left;

}

.iDQM-Section8 {
    background: #F1F7FF;
}

.iDQM-Section8-heading h2 {
    font-size: 35px;
    font-weight: 900;
}

.iDQM-Section8-heading h5 {
    color: #555;
    margin-bottom: 1.5rem;
}

.iDQM-Section8-items ul li {
    font-size: 14px;
    text-align: left;
}

.iDQM-Section9 {
    background: url(../Assets/iDQM/BG-section8.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 6rem 0;
}

.iDQM-Section9-heading h2 {
    text-align: start;
    font-size: 35px;
    font-weight: 900;
}

.iDQM-Section10-heading {
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 1rem;
}

.iDQM-Section10-heading h2 {

    font-size: 35px;
    font-weight: 900;
}

/* #fill-tab-example{
    border: 1px solid !important;
} */
/* .nav-item, .nav-fill>.nav-link {
        flex: 1 1 auto;
        text-align: center;
        border: 1px solid;
        margin: 0 1rem;
    } */


.idqm-updated {
    background-color: #AEE1FF;
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem
}

.idqm-updated-heading h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0b4a99;
}

.idqm-updated-banner-side {
    width: 70%;
}

.idqm-buttons[data-name="buttons"] div button:hover{
    background-color: #0b4a99 !important;
    color:white;
}

*[data-section-name]:not(.data-catalog,.catalog-rules) h1{
    color: #0b4a99;
    border-top: 7px solid;
    border-image: linear-gradient(to right, #AEE1FF 50%, transparent 50%) 100% 1;
    padding-top: 0.57rem;
}

.section-bg-cls{
    background-color: #f1f1f1;
    padding:3.5rem 0;
}
.catalog-rules  h1{
    color: #0b4a99;

}

.catalog-rules  .catalog-small-text{
    font-size:1.35rem;
    margin-bottom:10px;

}

.iDQM-Section10-heading h1{
    color:#0b4a99;
}
.data-catalog h1{
    color:#0b4a99;
}


*[data-section-name] .our-products-content{
    background-color: #fff;
}
@media only screen and (min-width: 1600px) {
    .idqm-updated-banner-side {
        width: 60%;
    }
}