 h1, h2,h3, h4, h5, h6{
    font-family: "Roboto", sans-serif;

}
.subscription-part1{
    background: url('../Assets/Subscription/LDGSubscription1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 5rem 0;
    margin-top: 3rem;
}
.subscription-heading h1{

    font-size: 38px;
    font-weight: 800;
    font-family: "Roboto", sans-serif;
    color: #0b4a99;
}
.subscription-heading p{
    font-size: 20px;
    font-weight: 400;
    margin: 1rem 0;
    font-family: "Roboto", sans-serif;
}
.subscription-head-images{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.subscription-head-images img{
    width: 100px;

}
.subscription-part2{
    background: url('../Assets/Subscription/LDGSubscription2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 5rem 0;
   
}
.subscription-card{
    padding: 1rem;
    border: 1px solid #ABBDEE;
    border-radius: 15px;
    background: #fff;
   
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.subscription-card:hover{
    background: linear-gradient(#ABBDEE, #fff);
    padding: 2.5rem 1rem;
    border-radius: 15px;
    margin: -1.5rem -0.5rem;
    transition:  0.5s;
    border: none;
    box-shadow: none;
    

}

.subscription-card2{
    background: linear-gradient(#ABBDEE, #fff);
    padding: 2.5rem 1rem;
    border-radius: 15px;
    margin: -1.5rem -0.5rem;
}

.subscription-card-title {
    margin: 1rem 0;
}
.subscription-card-title h5{
font-size: 25px;
font-weight: 800;
}
.subscription-card-title p{
    color: rgb(77, 76, 76);
}
.package-users{
    margin: 1rem 0;

}
.package-users h6{
    font-size: 15px;
}
.buy-now-btn{
    margin: 1.5rem 6rem;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
}
.buy-now-btn-design{
    background: #fff !important;
    color: #000 !important;
    border:1px solid #000;
}

.subscription-card-points{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    gap: 1rem;
    padding: 0 1.5rem;
    
    
}
.subscription-card-points p{
font-size: 14px;
color: #555;
line-height: 30px;
}
.service-images img{
width: 35px;
}
.service-images p{
    font-size: 10px;
}

.subscription-faq{
    background: url('../Assets/Subscription/LDGSubscription3.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4rem 0;
}
.faq-title h3{
font-size: 30px;
text-align: start;
color: #fff;
}

.faq-image img{
width: 100%;

}
.faq-accordian{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.faq-answers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    text-align: start;
}
.youtube-button{
    width: 10%;
    height: 10%;
}
.youtube-btn{
   
    background-color: red !important;
    color: white !important;
border: none;

   
    
}

@media  (max-width: 988px) {
    .subscription-card2{
        background: linear-gradient(#6f8cb1, #fff);
        padding: 2.5rem 1rem;
        border-radius: 15px;
        margin: 1rem  0;
    }
    .faq-image{
    margin-top: 1rem;
    }
}

.css-dv0mq-MuiTableCell-root {
    width: 200px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 3.43 !important;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: right;
    padding: 6px 16px;
    color: rgba(0, 0, 0, 0.87);
}