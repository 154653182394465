.Ai-Lens-top {
  background-color: #aee0ff;
  padding: 50px 0px;
}


.AiLens-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AiLens-heading h1 {
  color: #0a4999;
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 36px;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

.AiLens-heading p {
  font-size: 20px;
  color: #555;
  margin: 0;
  text-align: left;
}

.Ai-Lens-section2 {
  /* background: url(../Assets/AiLens/ai-lens-background.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 22rem 0 15rem 0; */
  margin-top: 3rem;
}

.Ai-Lens-section2-heasing {
  margin-top: 3rem;
}

.Ai-Lens-section2-heasing h3 {
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 40px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  margin-bottom: 2rem;
}

.Ai-Lens-section2-parts {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  /* margin-top: 3rem; */
  padding: -1rem;


}

.Ai-Lens-section2-parts-content {
  margin-top: 2rem;
}

.Pilogs-copilot h2 {
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 32px;
  color: #0a4999;
}

.Pilogs-copilot p {
  font-size: 15px;
}

.Ai-lens-conceived {
  width: 60px;
  /* background: #a9cbe9; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  padding: 0.5rem;
  border-radius: 10px 10px 0 10px;
  position: relative;
  right: 1px;
}


.Ai-Lens-section2-parts-content-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /* text-align: right; */
  margin-top: 2rem;
  margin-right: 5rem;
}

.Ai-Lens-section2-parts-content h5 {
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
  color: #0b4a99;

}

.Ai-Lens-section2-parts-content-2 h5 {
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
  color: #0b4a99;
}

.Ai-Lens-section2-parts-content-2 p {
  color: #000;
}

.Ai-Lens-section2-parts-content p {
  color: #000;
}


.explore-ailens {
  background: #f6f6f6;
  padding: 50px 0;
  margin: 30px 0px;
}
.explore-ailens .empty-div{
  margin-top: 0;
}

.explore-ailens-heading h2 {
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 32px;
  text-align: left;
  color: #0a4999;
  padding-bottom: 15px;
}
.explore-ailens .rounded{
  background-color: #fff;
}
.explore-topics {
  margin-bottom: 0px !important;
}

.explore-topics li {
  font-size: 14px;
}

.ai-lens-form-section {
  background: url(../Assets/AiLens/BG-DEMO2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 20rem 0;
}

.AI-Lens-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  background-color: #fff;
  color: #000;
  /* border: 1px solid #333; */
}

.usecase-redefine {
  /* margin: 2rem; */
  /* padding: 2rem; */
  font-weight: 800;
  color: #0a4999;
  font-size: 32px; 
}
.unboldtext{
  font-weight: 500;
}





.message,
.signin {
  font-size: 22px;
  text-align: start;
  color: #000;
}

.signin {
  text-align: center;
}

.signin a:hover {
  text-decoration: none !important;
}

.signin a {
  text-decoration: none !important;
}


.flex {
  display: flex;
  width: 100%;
  gap: 6px;
}

/* .AI-Lens-form label {
    position: relative;
  } */

.AI-Lens-form .input {
  background-color: #a8c3f8;
  color: #fff;
  width: 100%;
  padding: 20px 05px 05px 10px;
  outline: 0;
  /* border: 1px solid rgba(105, 105, 105, 0.397); */
  border-radius: 10px;
}

.AI-Lens-form .input::placeholder {
  color: #fff;
  position: absolute;
  left: 10px;
  top: 12px;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.AI-Lens-form label .input:placeholder-shown+span {
  top: 12.5px;
  font-size: 0.9em;
}

.AI-Lens-form label .input:focus+span,
.AI-Lens-form label .input:valid+span {
  color: #fff;
  top: 0px;
  font-size: 0.7em;
  font-weight: 600;
}

.input {
  font-size: medium;
}

.submit {
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  transform: .3s ease;
  background-color: #00bfff;
}

.submit:hover {
  background-color: #00bfff96;
}

.ai-lens-cta h2 {
  font-size: 32px;
  font-weight: 800;
  text-align: start;
  color: #0a4999;
}
.asktheexperttext {
  text-align: left;
  font-size: 22px;
  margin-bottom: 15px;
}
.ai-lens-cta p {
  font-size: 15px;
  font-weight: 400;
  text-align: start;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}